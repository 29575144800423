export const SET_USER = "SET_USER";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_USERNAME = "SET_USER_USERNAME";
export const SET_USER_CREDIT_AMOUNT = "SET_USER_CREDIT_AMOUNT";
export const SET_USER_LOAD_AMOUNT = "SET_USER_LOAD_AMOUNT";
export const SET_USER_JOINED_DATE = "SET_USER_JOINED_DATE";
export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_USER_VERIFIED = "SET_USER_VERIFIED";
export const SET_SENDER_INFO = "SET_SENDER_INFO";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const CLEAR_USER = "CLEAR_USER";
