import React from "react";
import "../styles/Global.css";

export default function Footer() {
  return (
    <footer className="footer">
      <p>© 2023 KEMLabels. All rights reserved.</p>
    </footer>
  );
}
